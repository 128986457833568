$theme-colors: (
    'primary': #ffc107,
    'secondary': #f8f9fa,
    'success': #198754,
    'info': #0dcaf0,
    'danger': #dc3545,
    'light': #f8f9fa,
    'dark': #212529
);

.required:after {
    content: '*';
    color: #dc3545;
}

$enable-shadows: true;
$enable-rounded: false;
$enable-gradients: true;
@import '~bootstrap/scss/bootstrap';